import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ImageContentBlock, ImageTiles, ProfileTiles, /*Timeline*/ } from "../../components/General/General";

import DefaultHeader from '../../assets/img/default-header.jpeg';
import DefaultMetaHeader from '../../assets/img/default-metaheader.jpg';

import WhoWeAreImage from '../../assets/img/who-we-are-image.jpeg'
import MissionImage from '../../assets/img/mission-image.jpeg';
import VisionImage from '../../assets/img/vision-image.jpeg';
import WhatWeStandFor from '../../assets/img/what-we-stand-for.jpeg';
import CareersImage from '../../assets/img/careers-image.jpeg';


import laura from '../../assets/profile/laura.jpeg';
import ashish from '../../assets/profile/ashish.jpeg';
import krishna from '../../assets/profile/krishna.jpeg';

import startupluxumbourglogo from '../../assets/logos/startup-luxembourg-logo.jpeg';
import push2023logo from '../../assets/logos/push2023-logo.jpg';
import esabiclogo from '../../assets/logos/esabichessen-logo.jpg';
import existlogo from '../../assets/logos/exist-logo.png';
import migrantlogo from '../../assets/logos/the-migrant-accelator.png';

function About () {
    let buttons = [
        {link:"/careers",label:"To Careers"}
    ]
    return (
        <>
            <MetaData 
                title="About reLi Energy: Pioneering Sustainable Battery Optimization"
                description="At reLi Energy, we are at the forefront of innovation, pioneering cutting-edge software solutions that revolutionize battery operations for peak performance and longevity."
                image={DefaultMetaHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader 
                head1="About Us"
                head2="Get to know more about reLi and our journey"
                image={DefaultHeader}
            />
            <ImageContentBlock
                head="Who are we?"
                content="At reLi Energy, we are at the forefront of innovation, pioneering cutting-edge software solutions that revolutionize battery operations for peak performance and longevity. Our commitment to sustainability drives our state-of-the-art technology, optimizing battery health. We empower businesses to maximize their investments and contribute significantly to building a greener and more sustainable world."
                image={WhoWeAreImage}
                mobileimage={false}
            />
            <ImageContentBlock
                head="Our mission is to revolutionize energy storage"
                content="... through advanced battery optimization software and enhancing battery performance, we can promote a greener and more sustainable world. By offering cutting-edge solutions for battery intelligence, we aim to enhance energy storage performance and contribute to a cleaner future powered by advanced battery technology."
                image={MissionImage}
                inverse={true}
                mobileimage={false}
            />
            <ImageContentBlock
                head="Our vision is to be at the forefront"
                content="... to empower individuals, businesses, and industries to unlock the full potential of energy storage while prioritizing environmental sustainability. We are dedicated to shaping the future of energy, optimizing batteries, and revolutionizing the way we store and use power for a cleaner and more efficient energy transition."
                image={VisionImage}
                mobileimage={false}
            />
            <ImageContentBlock
                head="What we stand for?"
                content="Committed to maximizing efficiency and fostering sustainability, we champion a transformative approach that propels the transition to renewables. Beyond innovation, we leverage advanced technology to advance the renewable energy sector, working tirelessly to create a greener and more resilient future for our planet."
                image={WhatWeStandFor} 
                inverse={true}
                mobileimage={false}
            />
            <ProfileTiles
                head="Our Founders"
                tiles={[
                    {head:"Ashish Guhan Baskar",image:ashish,position:"Chief Sales Officer",link:"https://www.linkedin.com/in/baskarashish/"},
                    {head:"Laura Laringe",image:laura,position:"Chief Executive Officer",link:"https://www.linkedin.com/in/lauralaringe/"},
                    {head:"Krishna Kumar Rathinam",image:krishna,position:"Chief Technology Officer",link:"https://www.linkedin.com/in/krishna-kumar-rathinam/"}
                ]}
            />
            {/*<Timeline
                head="Our Story"
                timeline={[
                    {time:"Jun 2020",event:"First ideation of reLi at ESADE Business School",location:"Barcelona, Spain"},
                    {time:"Sept 2020",event:"Commencement of technical development at TU Eindhoven",location:"Eindhoven, the Netherlands"},
                    {time:"Apr 2021",event:"reLi secures victory in the EIT InnoEnergy Battle of Green Talent among 60 startup ideas",location:"Europe"},
                    {time:"Jan 2022",event:"Founders of reLi relocate to Headquaters",location:"Darmstadt, Germany"},
                    {time:"Apr 2022",event:"Release of reLi's prototype and launch of its first lab pilot project",location:"Darmstadt, Germany"},
                    {time:"Aug 2022",event:"reLi receives the EXIST Startup Grant from BMWi (Federal Ministry for Economic Affairs and Climate Action)",location:"Darmstadt, Germany"},
                    {time:"Oct 2022",event:"Establishment of reLi Energy GmbH with its headquarters in Darmstadt, Germany",location:"Darmstadt, Germany"},
                    {time:"Feb 2023",event:"reLi gains support from the ESA-BIC funding program",location:"Darmstadt, Germany"},
                    {time:"Apr 2023",event:"Initiation of reLi's first pilot project, marking significant enhancements to its software",location:"Rüsselsheim am Main, Germany"},
                    {time:"Aug 2023",event:"reLi is awarded the push! grant by the Hessian Ministry of Economics, Energy, Transport, and Housing",location:"Wiesbaden, Germany"},
                    {time:"Oct 2023",event:"reLi attains the Fit4start grant from LuxInnovation. Submission of reLi's first patent",location:"Luxemburg, Luxemburg"},
                ]}
            />*/}
            <ImageContentBlock
                head="Want to join our team?"
                button={buttons}
                image={CareersImage} 
                inverse={true}
                mobileimage={false}
            />
            <ImageTiles
                head="Supported by"
                tiles={[
                    startupluxumbourglogo,
                    push2023logo,
                    esabiclogo,
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLqzj30XHuCLfNANaASvzyRDwUrUnYK0YQ_YGLG1druQ&s',
                    existlogo,
                    migrantlogo,
                ]}
            />
            <FooterBar/>
        </>
    )
}

export default About;
