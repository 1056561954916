import React from 'react';
import { useNavigate } from 'react-router-dom';

function HomeRedirect() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
};

export default HomeRedirect;
