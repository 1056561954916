import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ImageComp from "../ImageComp/ImageComp";

import CareersForm from "./CareersForm";

import './General.css';
import './InverseGeneral.css';

const ImageHeader = ({image,head1,head2,button}) =>{
    let buttonlist=[]
    if(button)
        buttonlist.push(button.map((button,index)=>{return <Link to={button.link} className="gen-image-header-button button button-primary">{button.label}</Link>}))
    return(
        <div className="gen-image-header" style={{backgroundImage:`url(${image})`}}>
            <div className="gen-image-block gen-image-block-head head1">{head1}</div>
            <div className="gen-image-block gen-image-block-head head2">{head2}</div>
            <div className="gen-image-block-actions">{buttonlist}</div>
        </div>
    )
}

const SmallImageHeader = ({image,head1,head2,button}) =>{
    let buttonlist=[]
    if(button)
        buttonlist.push(button.map((button,index)=>{return <Link to={button.link} className="image-header-button">{button.label}</Link>}))
    return(
        <div className="gen-image-header-small" style={{backgroundImage:`url(${image})`}}>
            <div className="gen-image-block gen-image-block-head head1">{head1}</div>
            <div className="gen-image-block gen-image-block-head head2">{head2}</div>
            <div className="gen-image-block-actions">{buttonlist}</div>
        </div>
    )
}

const PageTile = (tile,defaultimg,contain) =>{
    let tileimg = defaultimg;
    if(tile.img)
        tileimg = tile.img;
    return (
        <div className='gen-page-tile'>
            {contain?<Link to={tile.link} className='gen-page-tile-img-contain'><ImageComp aspectratio="1.78"><img src={tileimg}/></ImageComp></Link>:<Link to={tile.link} className='gen-page-tile-img'><ImageComp aspectratio="1.78"><img src={tileimg}/></ImageComp></Link>}
            <div className='gen-page-tile-content'>
                <Link to={tile.link} className='gen-page-tile-context head4'>{tile.head}</Link>
                <div className='gen-page-tile-context content-small'>{tile.content}</div>
                <div className='gen-page-tile-context-div'>
                    <Link to={tile.link} className='gen-page-tile-context-link content-small'>Read more ...</Link>
                    <div className='gen-page-tile-context content-small'>{tile.date}</div>
                </div>
            </div>
        </div>
    )
}

const PageTiles = ({tiles, defaultimg, contain}) =>{
    let pageTiles = [];
    pageTiles = tiles.map((tile,index)=>{ return PageTile(tile,defaultimg,contain)});
    return(
        <div className='gen-page-tiles'>
            {pageTiles}
        </div>
    )
}

const ImageTiles = ({head, tiles}) =>{
    let imageTiles = [];
    imageTiles = tiles.map((tile,index)=>{ return <div className='gen-image-tile'><img src={tile}></img></div>});
    return(
        <div className='gen-image-tiles'>
            <div className="gen-image-tiles-head head3">{head}</div>
            <div className="gen-image-tiles-div">
                {imageTiles}
                {imageTiles}
                {imageTiles}
            </div>
        </div>
    )
}

const ProfileTile = (tile) =>{
    return (
        <Link to={tile.link} className='gen-profile-tile'>
            <div className='gen-profile-tile-img'><ImageComp aspectratio="1"><img src={tile.image}/></ImageComp></div>
            <div className='gen-profile-tile-content head4'>{tile.head}</div>
            <div className='gen-profile-tile-content gen-profile-tile-position content-small'>{tile.position}</div>
        </Link>
    )
}

const ProfileTiles = ({head,tiles}) =>{
    let tileList = [];
    tileList.push(tiles.map((tile,index)=>{return ProfileTile(tile)}));
    return (
        <div className='gen-profile-tiles'>
            <div className='gen-profile-tiles-head head3'>{head}</div>
            <div className='gen-profile-tiles-div'>{tileList}</div>
        </div>
    )
}

const DivisionBlockContent = (content) =>{
    if(content.link)
        return (
            <Link to ={content.link} className="gen-division-block-content-link">
                {content.image?<div className="gen-division-block-content-img"><ImageComp aspectratio="1.7"><img src={content.image}/></ImageComp></div>:''}
                {content.icon?<div className="gen-division-block-content-icon"><ReactSVG src={content.icon}/></div>:''}
                {content.head?<div className="gen-division-block-content-head head4">{content.head}</div>:''}
                {content.content?<div className="gen-division-block-content-content content">{content.content}</div>:''}
            </Link>
        )
    let buttons = [];
    if(content.button)
            buttons.push(content.button.map((button,index)=>{return <Link to={button.link} className="gen-division-block-content-button button button-primary">{button.label}</Link>}))
    return (
        <div className="gen-division-block-content-div">
            {content.image?<div className="gen-division-block-content-img"><ImageComp aspectratio="1.7"><img src={content.image}/></ImageComp></div>:''}
            {content.icon?<div className="gen-division-block-content-icon"><ReactSVG src={content.icon}/></div>:''}
            {content.head?<div className="gen-division-block-content-head head4">{content.head}</div>:''}
            {content.content?<div className="gen-division-block-content-content content">{content.content}</div>:''}
            {content.button?buttons:''}
        </div>
    )
}

const DivisionBlock = ({head,contents,inverse}) => {
    let contentlist = contents?contents.map((content,index)=>{return DivisionBlockContent(content)}):[];
    if(inverse)
        return(
            <div className="inv gen-division-block">
                <div className="gen-division-block-head head3">{head}</div>
                <div className="gen-division-block-content">{contentlist}</div>
            </div>
        )

    return(
        <div className="gen-division-block">
            <div className="gen-division-block-head head3">{head}</div>
            <div className="gen-division-block-content">{contentlist}</div>
        </div>
    )
}

const LoginDivisionBlockContent = (content) =>{
    let buttons = [];
    if(content.button)
            buttons.push(content.button.map((button,index)=>{return <Link to={button.link} className="gen-division-block-content-button button button-primary">{button.label}</Link>}))
    return (
        <div className="gen-division-block-content-div gen-login-division-block-content-div">
            {content.image?<div className="gen-division-block-content-img"><img src={content.image}/></div>:''}
            {content.icon?<div className="gen-division-block-content-icon"><ReactSVG src={content.icon}/></div>:''}
            {content.head?<div className="gen-division-block-content-head head4">{content.head}</div>:''}
            {content.content?<div className="gen-division-block-content-content gen-login-division-block-content-content content">{content.content}</div>:''}
            {content.button?<div className="gen-login-division-block-button-div">{buttons}</div>:''}
        </div>
    )
}

const LoginDivisionBlock = ({head,contents,inverse}) => {
    let contentlist = contents?contents.map((content,index)=>{return LoginDivisionBlockContent(content)}):[];
    return(
        <div className="gen-division-block">
            <div className="gen-division-block-head head3">{head}</div>
            <div className="gen-division-block-content gen-login-division-block-content">{contentlist}</div>
        </div>
    )
}

const ImageContentBlock = ({head,content,image,button,inverse,mobileimage=true}) =>{
    let buttonlist=[]
    if(inverse){
        if(button)
            buttonlist.push(button.map((button,index)=>{return <Link to={button.link} className="gen-image-header-button button button-secondary">{button.label}</Link>}))
        return(
            <div className="inv gen-image-content-block">
                {mobileimage?<div className="gen-image-content-block-image"><img src={image}/></div>:<div className="gen-image-content-block-image gen-image-content-block-image-mobile"><img src={image}/></div>}
                <div className="gen-image-content-block-content">
                    <div className="gen-image-content-block-content-head head3">{head}</div>
                    <div className="gen-image-content-block-content-content content">{content}</div>
                    <div className="gen-image-content-block-actions">{buttonlist}</div>
                </div>
            </div>
        )
    }
    if(button)
        buttonlist.push(button.map((button,index)=>{return <Link to={button.link} className="gen-image-header-button button button-primary">{button.label}</Link>}))
    
    return(
        <div className="gen-image-content-block">
            <div className="gen-image-content-block-content">
                <div className="gen-image-content-block-content-head head3">{head}</div>
                <div className="gen-image-content-block-content-content content">{content}</div>
                <div className="gen-image-content-block-actions">{buttonlist}</div>
            </div>
            {mobileimage?<div className="gen-image-content-block-image"><img src={image}/></div>:<div className="gen-image-content-block-image gen-image-content-block-image-mobile"><img src={image}/></div>}
        </div>
    )
}

const TimelineTrail = (timelineEvent,index) =>{
    if(index%2===0)
        return (
            <div className="gen-timeline-trail-block-div">
                <div className="gen-timeline-trail-block">
                    {timelineEvent.time?<div className="gen-timeline-trail-content gen-timeline-trail-content-time content-sub">{timelineEvent.time}</div>:''}
                    {timelineEvent.location?<div className="gen-timeline-trail-content content-form">{timelineEvent.location}</div>:''}
                    {timelineEvent.event?<div className="gen-timeline-trail-content content-small">{timelineEvent.event}</div>:''}
                </div>
                <div className="gen-timeline-trail-block-line"></div>
                <div className="gen-timeline-trail-block-dot"></div>
                <div className="gen-timeline-trail-block-gap"></div>
            </div>
        )
    return (
        <div className="gen-timeline-trail-block-div">
            <div className="gen-timeline-trail-block-gap"></div>
            <div className="gen-timeline-trail-block-dot"></div>
            <div className="gen-timeline-trail-block-line"></div>
            <div className="gen-timeline-trail-block">
                {timelineEvent.time?<div className="gen-timeline-trail-content gen-timeline-trail-content-time content-sub">{timelineEvent.time}</div>:''}
                {timelineEvent.location?<div className="gen-timeline-trail-content content-form">{timelineEvent.location}</div>:''}
                {timelineEvent.event?<div className="gen-timeline-trail-content content-small">{timelineEvent.event}</div>:''}
            </div>
    </div>
    )
}

const Timeline = ({head,timeline}) =>{
    let timelineTrail = [];
    timelineTrail.push(timeline.map((timelineEvent,index)=>{return TimelineTrail(timelineEvent,index)}));
    return (
        <div className="gen-timeline">
            <div className="gen-timeline-head head3">{head}</div>
            <div className="gen-timeline-trail">
                {timelineTrail}
            </div>
        </div>
    )
}

const CareersList = (listitems) =>{
    let list = [];
    list.push(listitems.map((items,index)=>{return <li className="content">{items}</li>}))
    return (
        <ul className="careers-list">
            {list}
        </ul>
    )
}

const CareerBlock = ({position, applicationid, responsibilities, requirement, benifits}) =>{
    return(
        <div className='career-block'>
            <div className="career-block-head head3">{position}</div>
            <div className="career-block-div">
                <div className='career-content'>
                    <div className="career-block-content head4">About reLi</div>
                    <div className="career-block-content content">reLi is a pioneering startup that is revolutionizing the energy storage space. Our innovative technology extends the life of stationary energy storage batteries by up to 35%, while also increasing energy cost savings by up to 70%.</div>
                    <div className="career-block-content content">Our innovative software connects to batteries to monitor their performance in real-time, using advanced algorithms and IoT technology to optimize their usage and minimize degradation. This allows our customers to get the most out of their batteries, ensuring that they last longer and perform more efficiently.</div>
                    <div className="career-block-content content">At reLi, we're at the forefront of sustainable energy solutions, committed to making a positive impact on the environment. Join our dynamic team to be part of revolutionizing the energy storage landscape.</div>

                    {responsibilities?<><div className="career-block-content head4">Key Responsibilities</div>{CareersList(responsibilities)}</>:''}

                    {requirement?<><div className="career-block-content head4">Requirements</div>{CareersList(requirement)}</>:<div className='career-block-content head3'>Don't find a suitable position? Send us an open application!</div>}

                    <div className="career-block-content head4"> Benefits</div>
                    {CareersList(benifits)}
                </div>
                <div className='career-form'>
                    <CareersForm
                        position={position}
                        application_id={applicationid}
                    />
                </div>
            </div>
            <div className="career-block-content content">reLi is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, colour, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability or age.</div>
            <div className="career-block-content content">Further questions please mail to jobs@relienergy.de</div>
        </div>
    )

}

const CareerListItems = (job) =>{
    let locations = [];
    locations.push(job.location.map((location,index)=>{return <div className='gen-career-list-item-content gen-career-list-item-content-location content-small'><b>{location}</b></div>}))
    return (
        <Link to={job.link} className='gen-career-list-item'>
            <div className="gen-career-list-item-position">
                <div className='gen-career-list-item-head head4'>{job.position}</div>
                <div className="gen-career-list-item-context">
                    <div className='gen-career-list-item-content content'>{job.format}</div>
                    {locations}
                </div>
            </div>
            <div className='gen-career-list-item-arrow head3'><FontAwesomeIcon icon={faAngleRight} /></div>
        </Link>
    )
}

const CareerList = ({jobs}) =>{
    let listItems = [];
    listItems.push(jobs.map((job,index)=>{return CareerListItems(job)}))
    return (
        <div className="gen-career-list">
            <div className="head3">Current Offerings</div>
            {listItems}
        </div>
    )
}

export {ImageHeader, SmallImageHeader, PageTiles, ImageTiles, DivisionBlock, ImageContentBlock, ProfileTiles, Timeline, CareerBlock, CareerList, LoginDivisionBlock}