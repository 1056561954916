import React from "react";
import { ReactDOM, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import './ArticleData.css';

const ImageHeader = ({image,head1}) =>{
    return(
        <div className="art-image-header" style={{backgroundImage:`linear-gradient(0.25turn, rgba(255, 255, 255, 1), rgba(21, 54, 138, 0.1)),url(${image})`}}>
            <div className="art-image-block gen-image-block-head head1">{head1}</div>
        </div>
    )
}

const ArticleTitle = ({head}) =>{
    return <div className='art-title head2'>{head}</div>

}

const ArticleSection = ({head, content, children, inverse}) =>{
    if(inverse)
        return(
            <div className='art-section art-inv'>
                <div className='art-section-title-div'>
                    <div className='art-section-title-head head3'>{head}</div>
                    <div className='art-section-title-content content-art'>{content}</div>
                </div>
                {children}
            </div>
        )
    return(
        <div className='art-section art-nor'>
            <div className='art-section-title-div'>
                <div className='art-section-title-head head3'>{head}</div>
                <div className='art-section-title-content content-art'>{content}</div>
            </div>
            {children}
        </div>
    )
}

const ArticleContents = ({contents}) =>{
    let contentList = [];
    contentList.push(contents.map((content,index)=>{return <div className='art-content content-art'>{content}</div>}))

    return (
        <div className='art-contents'>{contentList}</div>    
    )
}

const ArticleImage = ({image, label, portrait, children}) => {
    let orientation = portrait?'art-img-portrait':'art-img-landscape';
    if(children)
        return(
            <div className='art-image'>
                <div className={`art-image-img-div ${orientation}`}>
                    <img src={image}/>
                    <div className='art-image-label head5'>{label}</div>
                </div>
                <div className='art-image-content-div'>{children}</div>
            </div>
        )
    return(
        <div className='art-image'>
            <div className='art-image-img-div-full'>
                <img src={image}/>
                <div className='art-image-label head5'>{label}</div>
            </div>
        </div>
    )
}

const ArticleList = ({contents,numbered}) =>{
    let contentlist = []
    contentlist.push(contents.map((content,index)=>{return <li className='art-content-list content-art'><b>{content.head}</b>{content.content}</li>}))
    if(numbered)
        return(
            <ol className='art-contents'>{contentlist}</ol>
        )
    return(
        <ul className='art-contents'>{contentlist}</ul>
    )
}

const ArticleQuote = ({content}) =>{
    return (
        <div className='art-quote'>
            <div className='art-quote-content head2'>"{content}"</div>
        </div>
    )
}

const ArticleExample = ({head,content}) =>{
    return (
        <div className='art-example'>
            <div className="art-example-head head4">{head}</div>
            <div className="art-example-content content-art">{content}</div>
        </div>
    )
}

const ArticleShare = ({request,requestlink,link})=>{
    return (
        <div className='art-share'>
            <div className='art-share-contact'>
                <Link to="/contact" className="art-share-button button button-primary">Contact Us</Link>
                <Link to={requestlink} className="art-share-button button button-primary">{request}</Link>
            </div>
            <div className='art-share-head head2'>Share this Page!</div>
            <div className='art-share-social'>
                <Link to={`http://twitter.com/share?url=${decodeURIComponent(link)}`} >
                    <FontAwesomeIcon className="art-share-button-social button button-primary" icon={faXTwitter} size="2xl" />
                </Link>
                <Link to= {`https://www.linkedin.com/sharing/share-offsite/?url=${link}`} >
                    <FontAwesomeIcon className="art-share-button-social button button-primary" icon={faLinkedinIn} size="2xl" />
                </Link>

            </div>
        </div>
    )
}

const ArticlePage = ({children}) =>{
    return <div className='art-page'>{children}</div>
}

const ArticleReferenceList = ({ references }) => {
  if (!references || references.length === 0) {
    return null; // Return nothing if there are no references
  }

  return (
    <div className="reference-list">
      <div className="art-reference-head">References</div>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {references.map((reference, index) => (
          <li className="art-reference-content" key={index}>
            <sup>{index + 1}</sup> {reference}
          </li>
        ))}
      </ul>
    </div>
  );
};

export { ImageHeader, ArticlePage, ArticleShare,
    ArticleTitle, ArticleSection, ArticleContents, ArticleImage, ArticleList,
    ArticleQuote, ArticleExample, ArticleReferenceList }